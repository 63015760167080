.App {
  text-align: center;
  min-height: 100vh;
  background-color: black;
  color:white;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  perspective: 1px;
  transform-style: preserve-3d;
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-wide {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-30%, -50%);
}

.left {
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(0%, -50%);
}

.right {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}



.cont {
  margin: 0;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #333;
  transition: all .7s;
  &.hidden {
    height: 0;
    transition: all .7s;
  }
}

.experiences-accordion .MuiAccordionSummary-content {
  justify-content: center !important;
}

.footer-link {
  width : 10% !important;
  color : #787878
}

.footer-link:hover {
  color : #ffffff
}

.fade-in {
  max-height: 1000px;
  /* height:'auto'; */
  transition: all 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: all 1s ease;
}

.background-down {
  background-image: url('./Components/Projects/BannerImages/down.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size:  100%;
  background-attachment: "fixed"
}

.background-up {
  background-image: url('./Components/Projects/BannerImages/up.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: "fixed"
}

.shadow
{
  box-shadow: 0 0 8px 8px white inset;
}

#boardslist {

  ::-webkit-scrollbar {
   width: 0.5em;
   height: 0.5em;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(97, 96, 96, 0.1);
  }

  ::-webkit-scrollbar-thumb {
   background-color: rgba(132, 132, 132, 0.911);
   border-radius: 3px;
  }
}

.box {
  width: 150px;
  height: 150px;
  border-radius: 1em;
  background-color: #f9f07e;
  margin-left: auto;
  margin-right: auto;
}

.framer-container {
  font-family: sans-serif;
  text-align: center;
  height: 10vh;
  background: linear-gradient(to right, #180d3c, #2d093d);
  color: #fff;
  padding-top: 320px;
}

/* width */
::-webkit-scrollbar {
  /* width: 20px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #49484c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8579ad;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4afc5;
}

.MuiSnackbar-root .MuiPaper-root {
  background:rgb(49, 49, 49);
  color:white
}
