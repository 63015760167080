.progress-bar {
  position: absolute;
  top: 63px;
  left: 49%;
  right: 0;
  height: 2px;
  width:1%;
  background: #cac4dd;
  /* transform-origin: -100%; */
  overflow: hidden;
  transition: all 0.5s ease-out
}

.main-tabs {
  display:flex; 
  /* width:20px; */
  /* transition: opacity 0.5s ease-in; */
  transition: width 0.7s ease-in-out, opacity 0.3s ease-in-out;
}

.no-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.main-bar {
  transition : box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 1s ease-in !important;
  height:65px
}