.blinking-cursor {
  /* font-weight: 100; */
  font-size: 1.2em;
  color: inherit;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;  }
}